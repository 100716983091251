import React, { Component } from "react";
import axios from 'axios';
import '../style/order.scss';
import Header from "./Header";
import CategoryMenu from "./CategoryMenu";
import Items from "./Items";
import { 
    Container
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { api_base, metaTitle } from '../config';

class Order extends Component {

  state = {
    items: [],
    category: 0,
    sentCart: false,
    total: 0,
    itemCart: [],
    searchInput: ''
  }

  componentDidMount() {
    const cart = sessionStorage.getItem('cart');
    let cart_item = [];
    if (cart) {
      cart_item = JSON.parse(cart);
    }else{
      cart_item = [];
    }
    if(cart_item.length > 0){
      let total = 0;
      cart_item.forEach(items => {
        total+=items.count;
      });
      this.setState({ 
        itemCart: cart_item,
        total: total
      });
    }
  }

  getItems(id, key = ''){
    axios.post(api_base+'/menu/items', 
        {
          category: id,
          key: key
        },{
          'Content-Type': 'application/x-www-form-urlencoded'
        }).then(response => {
          const datas = response.data;
          if(datas.status === true){
              this.setState({ items: datas.result });
          }
    }).catch((response) => {
      this.setState({ items: [] });
    });
  }

  changeCategory = (id) => {
    this.setState({ category: id });
    let key = this.state.searchInput;
    this.getItems(id, key);
  }

  addToCart = (id) => {
    let itemCart = this.state.itemCart;
    // let total = itemCart.length;
    let total = 0;
    let index = null;
    itemCart.forEach(items => {
      total+=items.count;
      if(items.id === id){
        index = itemCart.indexOf(items);
        // return true;
      }
    });
    if(index === null){
      let add = {
        id: id,
        count: 1,
        note: ''
      }
      itemCart.push(add);
    }else{
      let count = itemCart[index].count+1;
      let add = {
        id: id,
        count: count,
        note: ''
      }
      itemCart[index] = add;
    }
    this.setState({ 
      itemCart: itemCart,
      total: total+1
     });
  }

  sentCart = () => {
    let itemCart = this.state.itemCart;
    sessionStorage.setItem('cart', JSON.stringify(itemCart));
    this.props.history.push('/cart');
    this.setState({ sentCart: true });
  }

  searchChange = (event) => {
    let key = event.target.value;
    this.setState({
      [event.target.name]: key
    });

    let id = this.state.category;
    this.getItems(id, key);
  }

  clearSearch = () => {
    let key = '';
    this.setState({
      'searchInput': key
    });

    let id = this.state.category;
    this.getItems(id, key);
  }

  render() {
    if(this.state.sentCart === true){
      return <Redirect to='/cart' />
    }
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('Order') }</title>
        </Helmet>
        <Header />
        <Container>
          <div className="search-box">
            <button className="search-button"> </button>
            <input type="text" value={this.state.searchInput} name="searchInput" onChange={ this.searchChange } />
            { (this.state.searchInput !== '') ? <span className="cross" onClick={ this.clearSearch }>x</span> : '' }  
          </div>
          <CategoryMenu changeCategory={this.changeCategory} category={this.state.category}/>
          <Items items={this.state.items} addToCart={this.addToCart}/>
        </Container>
        <div className="order-cart" onClick={this.sentCart}>
          <label>{this.state.total}</label>
        </div>
      </React.Fragment>
    );
  }
}
export default Order;
