export const app_title = 'ezyERP Food';
export const app_for = 'Restaurant';
export const api_base = 'https://admin.bh.ezyerp.net/api/v1';
export const mobile = '97333014933';
export const currency = 'BD';
export const metaTitle = (title = '') => {
    if(title !== ''){
        title = ' | '+title;
    }
    return "ezyERP Food "+title;
}