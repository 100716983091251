import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import '../style/item.scss';
import { currency } from '../config';

class Items extends Component {
  addToCart = (id) => {
    this.props.addToCart(id);
  };

  round = (value) => {
    let num = parseFloat(value).toFixed(2);
    return num;
  }

  render() {
    let product = Object.values(this.props.items);
    return (
      <Row>
        {product.map((item, key) => {
          return (
            <Col md={3} sm={4} xs={6} className="sale-items" key={key}>
                  <div className="items">
                    <img
                      src={item.url}
                      alt={item.title}
                      className="category-logo"
                      onClick={() => this.addToCart(item.id)}
                    />
                    <h5 onClick={() => this.addToCart(item.id)} >{item.title}</h5>
                    <p>{item.description}</p>
                    <label>{this.round(item.price)} {currency}</label>
                    <button className="add-to-cart" onClick={() => this.addToCart(item.id)} >Add to cart</button>
                  </div>
                  
            </Col>
          );
        })}
      </Row>
    );
  }
}
export default Items;
