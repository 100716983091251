import React, {Component} from 'react';
import axios from 'axios';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { api_base } from '../config';
import '../style/menu.scss';

const MenuItem = ({title, url, selected}) => {
    return (
      <div className={`category-items ${selected ? 'active' : ''}`}>
            <img src={url} alt={title} className="category-logo" />
          <label>{title}</label>
      </div>
    )
};

export const Menu = (list, selected) =>
  list.map((item) => {
    return <MenuItem title={item.title} url={item.url} key={item.id} selected={selected} />;
});

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};

class CategoryMenu extends Component {
  state = {
    category: {}
  }

  componentDidMount() {
    axios.get(api_base+'/menu/category')
      .then(res => {
        const datas = res.data;
        if(datas.status === true){
          this.setState({ category: datas.result });

          this.props.changeCategory(datas.result[0].id);
        }
    }).catch((response) => console.log('error', response));
  }

  items = (id) => {
    this.props.changeCategory(id);
  }

  render() {
    let selected = this.props.category;
    let category = Object.values(this.state.category);
    const menu = Menu(category, selected);
    const ArrowLeft = Arrow({ text: '', className: 'arrow-prev' });
    const ArrowRight = Arrow({ text: '', className: 'arrow-next' });
    return (
      <div className="category-menu">
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={selected}
          onSelect={this.items}
        />
      </div>
    );
  }
}
export default CategoryMenu;