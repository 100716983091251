import React from 'react';
import logo from '../logo.svg';
import '../style/header.scss';
import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { app_title, app_for, mobile } from '../config';
import phonelogo from '../img/phone-call.png';

function Header(props) {
  return (
    <React.Fragment>
    <header>
      <Container className="top-head">
        <Row className="justify-content-between top-head-row">
          <Col><img src={logo} alt="logo" className="top-head-logo" /></Col>
          <Col><a href={'tel:+'+mobile} target="_blank" rel="noopener noreferrer">
            <img src={ phonelogo } alt="Call" className="call-logo"/>
            <label className="call-text">+{mobile}</label>
          </a></Col>
        </Row>
          <div className="message-box">
              <span></span>
          </div>
          <h2>{ app_title } <span>{ app_for }</span></h2>
      </Container>
    </header>
    <div className="header-mask"></div>
    <div className="clearfix"></div>
    </React.Fragment>
  );
}

export default Header;